
.about-hidden-2 {
  background-image: url("https://i.imgur.com/9l1PKOx.jpg");
  background-position: 10% 21%;
  background-origin: content-box;
  background-size: cover;
}

.projects-hidden-2 {
  background-image: url("https://apod.nasa.gov/apod/image/2211/2022_11_08_TLE_Trio_1500px.png");
  background-position: center;
  background-origin: content-box;
  background-size: 175%;
}

.experience-hidden-2 {
  background-image: url("https://i.imgur.com/ddJmNUy.png");
  background-position: center;
  background-origin: content-box;
  background-size: cover;
}

.hidden-image {
  height: calc(var(--vh, 1vh) * 33 - 14px);
}

.reveal > div {
  height: 100%;
}


.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: -webkit-fill-available;
  height: calc(var(--vh, 1vh) * 33 - 14px);
  min-height: 100%;
  color: black;
  width: 100vw;
  background-color: rgba(227, 227, 227, 0.9);
}

.overlay.inverted {
  color: white;
  background-color: rgb(27, 28, 29, 90%);
}

@media screen and (min-width: 720px) {
  .overlay {
    height: calc(100vh - 50px);
    width: calc(100vw/3);  
  }

  .about-hidden {
    background-position: 10% 21%;
  }
  
  .hidden-image {
    height: calc(100vh - 50px);
  }

  .about-hidden-2 {
    background-position: center;
  }

  .projects-hidden-2 {
    background-position: top;
    background-size: 210%;
  }
}

@media screen and (min-width: 720px) and (max-width: 990px) {
  .overlay {
    width: calc(100vw);
  }
}